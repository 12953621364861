import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import arrow from './../../../media/svg/Polygon 5.svg';
import { useTelegram } from '../../../contexts/TelegramContext';
import { decorateError } from '../../../utils/helpers';
import { useModal } from '../../../contexts/ModalContext';
import ModalLayout from '../../modals/ModalLayout/ModalLayout';
import AdWatched from '../../modals/info/AdWatched/AdWatched';
import { useHttp } from '../../../contexts/HttpContext';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useUser } from '../../../contexts/UserContext';
import { useLanguage } from '../../../contexts/LanguageContext';
dayjs.extend(relativeTime);

const WatchAd = () => {
  const [available, setAvailable] = useState(false);
  const [timeLeft, setTimeLeft] = useState('');
  const { open } = useModal();
  const { post, get } = useHttp();
  const { getUser } = useUser();
  const { lang } = useLanguage();

  const updateTimeLeft = (nextAdTime) => {
    const now = dayjs();
    const nextAd = dayjs(nextAdTime);
    const seconds = nextAd.diff(now, 'second') % 60;
    const minutes = nextAd.diff(now, 'minute');
    if (minutes <= 0 && seconds <= 0) {
      update();
    }
    // eslint-disable-next-line
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };

  const update = () => {
    get('/api/advertising').then((r) => {
      if (r.status === 'error') {
        alert(decorateError(JSON.stringify(r.message)));
      } else {
        setAvailable(r.data.canWatch);
        if (!r.data.canWatch) {
          setTimeLeft(updateTimeLeft(r.data.nextAdTime));
          const intervalId = setInterval(() => {
            setTimeLeft(updateTimeLeft(r.data.nextAdTime));
          }, 1000);
          return () => clearInterval(intervalId);
        }
      }
    });
  };
  const handleAdWatch = () => {
  
  };
  useEffect(() => {
    const intervalId = update();
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);
  return (
    <div className={`${styles.container} ${!available ? styles.notavailable : ''}`}>
      <div className={styles.inner}>
        <span className={styles.top}>{lang.ui.watchAd}</span>
        <span className={styles.top}>
          {lang.ui.renew}
          <span className={styles.yellow}> {lang.ui.energy}</span>
        </span>
      </div>
      <button
        disabled={!available}
        className={styles.button}
        onClick={handleAdWatch}
      >
        <img
          src={arrow}
          alt=''
        />
      </button>
      {!available && (
        <span className={styles.time}>
          {lang.ui.willBeAvailable} {timeLeft}
        </span>
      )}
    </div>
  );
};

export default WatchAd;
