import React from 'react';
import cn from 'classnames';
import styles from './Loading.module.css';

const Loading = ({ className = '', spinnerClassName = '' }) => {
  return (
    <div className={cn(styles.loading, className)}>
      <div className={cn(styles.spinner, spinnerClassName)} />
    </div>
  );
};

export default Loading;
