import React from 'react';
import { useUser } from '../../contexts/UserContext';
import { useLanguage } from '../../contexts/LanguageContext';
import { Loading as Loader } from '../../shared/ui';
// import TechnicalWorks from "../TechincalWorks";
import styles from './LoadingPage.module.css';

const LoadingPage = () => {
  const { loadingPercent } = useUser();
  const { lang } = useLanguage();

  // return <TechnicalWorks />;

  return (
    <div className={styles.root}>
      <div className={styles.backgroundImage} />

      <div className={styles.loadingContainer}>
        <div className={styles.blackBottomGradient} />
        <div className={styles.progressContainer}>
          <div
            className={styles.progressBar}
            style={{ width: `${loadingPercent}%` }}
          />
          <span className={styles.loadingText}>{lang.loading.loading}...</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
