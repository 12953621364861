import React from 'react';

import MenuContent from './MenuContent';
import WinnerContent from './WinnerContent';

const Footer = ({
  completed,
  currentScore,
  maxScore,
  onClicksInfoModalOpen,
  onGiftsModalOpen,
  onWithdraw,
  loadingWithdraw,
  gift,
  requiredFriends,
}) => {
  if (completed) {
    return (
      <WinnerContent
        loadingWithdraw={loadingWithdraw}
        onWithdraw={onWithdraw}
        gift={gift}
        requiredFriends={requiredFriends}
      />
    );
  }

  return (
    <MenuContent
      gift={gift}
      currentScore={currentScore}
      maxScore={maxScore}
      onClicksInfoModalOpen={onClicksInfoModalOpen}
      onGiftsModalOpen={onGiftsModalOpen}
    />
  );
};

export default Footer;
