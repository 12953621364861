import React from 'react';
import SmoothList from 'react-smooth-list';
import { Loading } from '../../../../shared/ui';

import CaseListItem from '../CaseListItem';
import styles from '../../index.module.css';

const CasesList = ({ cases, onDetailsClick }) => {
  if (!cases.length) return <Loading />;

  return (
    <SmoothList
      className={styles.casesList}
      childClassName={styles.casesListSmooth}
      delay={75}
    >
      {cases.map(({ ...caseData }, index) => (
        <CaseListItem
          key={caseData.id}
          isFirstItem={index === 0}
          onDetailsClick={onDetailsClick}
          {...caseData}
        />
      ))}
    </SmoothList>
  );
};

export default CasesList;
