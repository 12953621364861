import React from 'react';

import { useTelegram } from '../contexts/TelegramContext';
import { UserProvider } from '../contexts/UserContext';
import { HttpProvider } from '../contexts/HttpContext';
import { NotificationProvider } from '../contexts/NotificationsContext';
import { ModalProvider } from '../contexts/ModalContext';

import App from '../App';
import PcUser from '../components/PcUser/PcUser';
// import LoadingPage from '../components/LoadingPage';

const AppWrapper = () => {
  const { isReady, isPc } = useTelegram();

  if (isPc) return <PcUser />;

  // TODO: Обработать случай когда телеграм не подключился
  if (!isReady) return null;

  return (
    <HttpProvider>
      <UserProvider>
        <ModalProvider>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </ModalProvider>
      </UserProvider>
    </HttpProvider>
  );
};

export default AppWrapper;
