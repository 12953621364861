import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Bowser from 'bowser';

const TelegramContext = createContext(null);

export const TelegramProvider = ({ children }) => {
  const [tg, setTg] = useState(null);
  const [animations, setAnimations] = useState(() => JSON.parse(localStorage.getItem('animations')) ?? true);
  const [vibration, setVibration] = useState(() => JSON.parse(localStorage.getItem('vibration')) ?? true);
  const [isReady, setIsReady] = useState(false);
  const [isPc, setIsPc] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const Telegram = window.Telegram.WebApp;

    if (Telegram) {
      setTg(Telegram);
      Telegram.setBackgroundColor('#0a0a0a');
      Telegram.expand();
      Telegram.disableVerticalSwipes();
    }

  }, []);

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const platformType = browser.getPlatformType();
    if (platformType === 'desktop') {
      setIsPc(false);
    }
  }, [navigate]);

  useEffect(() => {
    if (tg) {
      setIsReady(true);
    }
  }, [tg]);

  useEffect(() => {
    localStorage.setItem('animations', JSON.stringify(animations));
  }, [animations]);

  useEffect(() => {
    localStorage.setItem('vibration', JSON.stringify(vibration));
  }, [vibration]);

  const handleClick = (style = 'light') => {
    if (!vibration) return;
    tg.HapticFeedback.impactOccurred(style);
  };

  return (
    <TelegramContext.Provider
      value={{ tg, isReady, isPc, handleClick, animations, setAnimations, vibration, setVibration }}
    >
      {children}
    </TelegramContext.Provider>
  );
};

export const useTelegram = () => useContext(TelegramContext);
